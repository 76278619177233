import {React, useEffect, useRef, useState, useReducer} from "react";
import { Grid, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import {Checkbox, Typography} from "@material-ui/core";
import {Input} from "@material-ui/core";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { DataGrid, GridColDef, GridValueGetterParams} from '@material-ui/data-grid';
// data
import mock from "../dashboard/mock";
import { createTheme } from '@material-ui/core/styles'
import MenuButton  from "@material-ui/icons/Menu"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
import {
  Menu,
  MenuItem,
  FormControlLabel,
  FormGroup,
  FormControl
} from "@material-ui/core";
import axios from 'axios';
import './styles.css';
import { ToastContainer, toast } from 'react-toastify';
import ManagerLekcji from "./ManagerLekcji";
const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
  datepicker: {
    backgroundColor: theme.palette.primary.main,
  },

}))



export default function ManagerGrup() {
  const classes = useStyles();

  const [groupUsersVisibility, setGroupUsersVisibility] = useState({});
  const [groupUsersData, setGroupUsersData] = useState({});
  const [groupName, setGroupName] = useState("");
  const [groups, setGroups] = useState([]);


  const toggleGroupUsers = (dataIndex, usersInGroup) => {
    setGroupUsersVisibility(prevVisibility => ({
      ...prevVisibility,
      [dataIndex]: !prevVisibility[dataIndex]
    }));

    setGroupUsersData(prevData => ({
      ...prevData,
      [dataIndex]: usersInGroup
    }));
  };
  const handleCreateGroup = async () => {
    const newGroup = {
      name: groupName,
    };

    try {
      const response = await axios.post(
        "https://ekoland-dziennik.pl/add_groups.php",
        newGroup
      );

      if (response.status === 200) {
        toast.success("Grupa stworzona poprawnie!");
        // Clear input field after successful creation
        setGroupName("");
        // Refresh group list
        fetchGroups();
      }
    } catch (error) {
      toast.error("Wystąpił błąd podczas tworzenia grupy!");
    }
  };
  const fetchUsersForGroup = async (group) => {
    try {
      const response = await axios.get(
        "https://ekoland-dziennik.pl/readStudents.php"
      );
  
      const usersData = response.data;
      const userIds = group.users;
      const usersInGroup = userIds.map(userId => usersData[userId] || null);
      return usersInGroup;
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania użytkowników grupy");
      return [];
    }
  };


  
  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(
        "https://ekoland-dziennik.pl/readStudents.php"
      );
      const usersArray = Object.values(response.data);
      return usersArray;
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania użytkowników");
      return [];
    }
  };
  const fetchGroups = async () => {
    try {
        const response = await axios.get(
            "https://ekoland-dziennik.pl/readGroups.php"
        );

        const parsedGroups = Object.entries(response.data).map(([id, group]) => ({
            id,
            ...group // Already in the correct format
        }));

        setGroups(parsedGroups);
    } catch (error) {
        console.error(error.response || error); // Log the error
        toast.error("Wystąpił błąd podczas wczytywania grup!");
    }
};
  useEffect(() => {
    fetchGroups();
  }, []);

  const handleDeleteGroup = async (groupId) => {
    try {
        const response = await axios.delete(
            `https://ekoland-dziennik.pl/delete_group.php?groupId=${groupId}`
        );

        if (response.status === 200) {
            toast.success("Grupa usunięta poprawnie!");
            // Refresh group list
            fetchGroups();
        }
    } catch (error) {
        console.error(error.response || error); // Log the error
        toast.error("Wystąpił błąd podczas usuwania grupy");
    }
};

  const fetchUserById = async (userId) => {
    try {
      const response = await axios.get(
        `https://ekoland-dziennik.pl/readStudents.php`
      );

      const usersData = response.data;
      const user = usersData[userId];
      return user;
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania użytkownika");
      return null;
    }
  };
  
  const populateGroupUsersData = async () => {
    const populatedData = {};
  
    await Promise.all(groups.map(async (group) => {
      const usersInGroup = await fetchUsersForGroup(group);
      populatedData[group.id] = usersInGroup;
    }));
  
    setGroupUsersData(populatedData);
  };
  
  useEffect(() => {
    populateGroupUsersData();
  }, [groups]);

  const options = 
  {
    textLabels: {


        body: {
          noMatch: "Nie ma żadnych newsów :(",

        },
      pagination: {
        next: "Następna strona",
        previous: "Poprzednia strona",
        rowsPerPage: "Wiersze na stronę:",
        displayRows: "z" // 1-10 of 30
      },
      toolbar: {
        search: "Szukaj",
        downloadCsv: "Pobierz CSV",
        print: "Drukuj",
        viewColumns: "Zobacz kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        title: "FILTRY",
        reset: "reset",          
      },
      viewColumns: {
        title: "Pokaż kolumny"
      },
      selectedRows: {
        text: "wierszy usuniętych",
        delete: "Usuń"
      }
  }
  }
  return (
    <>
      <Grid container spacing={4}>
        <ToastContainer />
        <Grid item xs={12}>
          <Widget title="Stwórz nową grupę">
            <TextField
              label="Nazwa grupy"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              fullWidth
              required
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateGroup}
              style={{color:"white"}}
            >
              Zapisz
            </Button>
          </Widget>
        </Grid>
        <Grid item xs={12}>
        <MUIDataTable
        title={"Lista grup"}
        data={Object.values(groups)}
        options={options}
        columns={[
          {
            name: "name",
            label: "Nazwa",
          },
          {
            name: "groupUsers",
            label: "Użytkownicy w grupie",
            options: {
              customBodyRenderLite: (dataIndex) => {
                const group = groups[dataIndex];
                const groupUsers = groupUsersData[group.id];
            
                if (groupUsers) {
                  const validUsers = groupUsers.filter(user => user !== null);
                  if (validUsers.length > 0) {
                    return (
                      <div>
                        {validUsers.map((user) => (
                          <div key={user.id}>
                            {`${user.first_name} ${user.last_name}`}
                          </div>
                        ))}
                      </div>
                    );
                  } else {
                    return null; // Don't render anything when no valid users
                  }
                }
            
                return null;
              },
            },
            
          },
          {
            name: "delete",
            label: "Usuń",
            options: {
              customBodyRenderLite: (dataIndex) => (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    const isConfirmed = window.confirm("Czy na pewno chcesz usunąć tę grupę?");
                    if (isConfirmed) {
                      handleDeleteGroup(groups[dataIndex].id);
                    }
                  }}
                >
                  Usuń
                </Button>
              ),
            },
          },
        ]}
      />
        </Grid>
      </Grid>
     
      
    </>
  );
}