import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { Select, TextField } from "@material-ui/core";

export default function Frekwencja(props) {
  const [lessonsData, setLessonsData] = useState([]);
  const [usersData, setUsersData] = useState({});
  const [groupsData, setGroupsData] = useState({});
  const [absenceData, setAbsenceData] = useState([]);

  useEffect(() => {
    // Fetch lessons data
    axios.get("https://ekoland-dziennik.pl/get_all_lessons.php")
      .then((response) => {
        setLessonsData(response.data.entries); // Set lessons data
      })
      .catch((error) => {
        console.error("Error fetching lessons:", error);
      });
  
    // Fetch users data
    axios.get("https://ekoland-dziennik.pl/readStudents.php")
      .then((response) => {
        setUsersData(response.data); // Set users data
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  
    // Fetch groups data
    axios.get("https://ekoland-dziennik.pl/readGroups.php")
      .then((response) => {
        setGroupsData(response.data); // Set groups data
      })
      .catch((error) => {
        console.error("Error fetching groups:", error);
      });
  }, []);

  useEffect(() => {
    // Calculate absence data (including dates, absence count, sum of lessons, and makeup info) for each user in each group
    const calculatedAbsenceData = {};

    lessonsData.forEach((lesson) => {
      const groupId = lesson.Grupa;
      const group = groupsData[groupId];

      if (!group) return;

      const lessonDate = lesson.Data;
      const lessonUsers = lesson.Uczestnicy;

      lessonUsers.forEach((lessonUser) => {
        const userId = lessonUser.internalId;
        const user = usersData[userId];

        if (!user) 
        return;

        if (user) {
          
        }

        if (!calculatedAbsenceData[groupId]) {
          calculatedAbsenceData[groupId] = {};
        }

        if (!calculatedAbsenceData[groupId][userId]) {
          console.log(calculatedAbsenceData)
          calculatedAbsenceData[groupId][userId] = {
            Group: group.name,
            UserName: `${user.first_name} ${user.last_name}`,
            Active: user.active ? "Tak" : "Nie",
            AbsenceCount: 0,
            LessonCount: 0,
            DatesOfAbsence: [],
            MakeupInfo: "",
          };
        }

        calculatedAbsenceData[groupId][userId].LessonCount += 1;

        if (!lessonUser.obecnosc) {
          calculatedAbsenceData[groupId][userId].AbsenceCount += 1;
          calculatedAbsenceData[groupId][userId].DatesOfAbsence.push(String(lessonDate));
        }
        calculatedAbsenceData[groupId][userId].FormattedDates = calculatedAbsenceData[groupId][userId].DatesOfAbsence.join(", ");
        if (lessonUser.odrabianie) {
          // Add makeup info
          if (calculatedAbsenceData[groupId][userId].MakeupInfo) {
            calculatedAbsenceData[groupId][userId].MakeupInfo += `, ${lessonDate} (${group.name})`;
          } else {
            calculatedAbsenceData[groupId][userId].MakeupInfo = `${lessonDate} (${group.name})`;
          }
        }
      });
    });

    setAbsenceData(calculatedAbsenceData);
  }, [lessonsData, usersData, groupsData]);

  const columns = [
    {
      name: "Group",
      label: "Grupa",
      options: 
      {
        filter: true,
        type:"checkbox"
      }
    },
    {
      name: "UserName",
      label: "Uczestnik",
    },
    {
      name: "Active",
      label: "Aktywny",
    },
    {
      name: "LessonCount",
      label: "Suma lekcji",
      filter:false
    },
    {
      name: "AbsenceCount",
      label: "Suma nieobecności",
    },
    {
      name: "FormattedDates",
      label: "Daty nieobecności",
    },
    {
      name: "MakeupInfo",
      label: "Odrabianie (beta)",
      options: {
        customBodyRender: (value) => {
          return value || "-";
        },
      },
    },
  ];


  const updatedColumns = columns.map(column => {
    if (column.name === 'Group') {
      return {
        ...column,
        options: {
          filter: true,
          filterType: 'checkbox' // Setting textfield filter for "Group"
        }
      };
    } else if (column.name === 'UserName') {
      return {
        ...column,
        options: {
          filter: true,
          filterType: 'textField' // Setting checkbox filter for "UserName"
        }
      };
    } else {
      return {
        ...column,
        options: {
          filter: true,
          filterType: 'checkbox' // Setting checkbox filter for other columns
        }
      };
    }
  });
  const absenceOptions = {

    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: "Niestety, nie znaleziono pasujących rekordów",
      },
      pagination: {
        next: "Następna strona",
        previous: "Poprzednia strona",
        rowsPerPage: "Wiersze na stronę:",
        displayRows: "z" // 1-10 of 30
      },
      toolbar: {
        search: "Szukaj",
        downloadCsv: "Pobierz CSV",
        print: "Drukuj",
        viewColumns: "Zobacz kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        title: "FILTRY",
        reset: "reset",          
      },
      viewColumns: {
        title: "Pokaż kolumny"
      },
      selectedRows: {
        text: "wierszy usuniętych",
        delete: "Usuń"
      }
  }
  };

  const absenceDataArray = [];

  // Flatten the calculatedAbsenceData into an array for MUIDataTable
  for (const groupId in absenceData) {
    for (const userId in absenceData[groupId]) {
      absenceDataArray.push(absenceData[groupId][userId]);
    }
  }

  return (
    <div>
      <MUIDataTable
        title="Frekwencja"
        data={absenceDataArray}
        columns={updatedColumns}
        options={absenceOptions}
      />
    </div>
  );
}
