import {React, useEffect, useRef, useState, useReducer} from "react";
import { Grid, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import {Checkbox, Typography} from "@material-ui/core";
import {Input} from "@material-ui/core";
// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { DataGrid, GridColDef, GridValueGetterParams} from '@material-ui/data-grid';
// data
import mock from "../dashboard/mock";
import { createTheme } from '@material-ui/core/styles'
import MenuButton  from "@material-ui/icons/Menu"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
import {
  Menu,
  MenuItem,
  FormControlLabel,
  FormGroup,
  FormControl
} from "@material-ui/core";
import axios from 'axios';
import './styles.css';
import { ToastContainer, toast } from 'react-toastify';
import Oceny from "./Oceny";

import { Add as Save
} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
  datepicker: {
    backgroundColor: theme.palette.primary.main,
  },

}))



export default function GrupaTestowa() {
  const classes = useStyles();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pesel, setPesel] = useState("");
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [file, setFile] = useState(null);
  const [editingUserId, setEditingUserId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditUserOpen = (user) => {
    setEditingUserId(user.id);
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setPesel(user.pesel);
    setIsModalOpen(true);
  };

  const handleEditUserClose = () => {
    setIsModalOpen(false);
    setEditingUserId(null);
    setFirstName("");
    setLastName("");
    setPesel("");
  };

  const handleEditUserSubmit = async () => {
    const updatedUser = {
      id: editingUserId,
      first_name: firstName,
      last_name: lastName,
      pesel: pesel,
    };

    try {
      const response = await axios.post("https://ekoland-dziennik.pl/modify_students.php", updatedUser);
      if (response.status === 200) {
        toast.success("Uczestnik zaktualizowany pomyślnie!");
        fetchUsers();
        handleEditUserClose();
      }
    } catch (error) {
      toast.error("Błąd podczas aktualizacji użytkownika.");
    }
  };
  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  };

  // Function to create users from the uploaded CSV file
  const handleCreateUsersFromCSV = async () => {
    if (!file) {
      toast.error('Wybierz plik CSV przed próbą utworzenia użytkowników.');
      return;
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const content = event.target.result;
        const lines = content.split('\n');

        for (let line of lines) {
          const [firstName, lastName, pesel] = line.split(','); // Assuming CSV structure: firstName, lastName, pesel
          const newUser = {
            first_name: firstName,
            last_name: lastName,
            pesel: pesel,
          };

          try {
            const response = await axios.post(
              'https://ekoland-dziennik.pl/write_students.php',
              newUser
            );

            if (response.status === 200) {
              toast.success('Użytkownik został pomyślnie utworzony.');
              fetchUsers(); // Refresh the user list
            }
          } catch (error) {
            toast.error('Błąd podczas tworzenia użytkownika.');
          }
        }
      } catch (error) {
        toast.error('Błąd podczas przetwarzania pliku CSV.');
      }
    };

    reader.readAsText(file);
  };


  const handleCreateUser = async () => {
    const newUser = {
      first_name: firstName,
      last_name: lastName,
      pesel: pesel,
    };

    if(firstName == ""||lastName ==""|| pesel == "")
    {
      toast.error("Wszystkie pola muszą zostać wypełnione!");
      return;
    }
    try {
      const response = await axios.post(
        "https://ekoland-dziennik.pl/write_students.php",
        newUser
      );

      if (response.status === 200) {
        toast.success("Uczestnik stworzony poprawnie!");
        // Clear input fields after successful creation
        setFirstName("");
        setLastName("");
        setPesel("");
        // Refresh user list
        fetchUsers();
      }
    } catch (error) {
      toast.error("Wystąpił błąd podczas dodawania uczestnika!");
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "https://ekoland-dziennik.pl/readStudents.php"
      );
      const usersArray = Object.values(response.data); // Convert object properties to an array
      setUsers(usersArray);
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania uczestników");
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchGroups();
  }, []);

  const handleDeleteUser = async (userId) => {
    try {
      const response = await axios.delete(
        `https://ekoland-dziennik.pl/delete_students.php?userId=${userId}`
      );

      if (response.status === 200) {
        toast.success("Uczestnik usunięty poprawnie!");
        // Refresh user list
        fetchUsers();
      }
    } catch (error) {
      toast.error("Wystąpił błąd podczas usuwania uczestnika");
    }
  };
  const handleAddUserToGroup = async (userId, groupId) => {
    const requestData = {
      user_id: userId,
      group_id: groupId,
    };
  
    try {
      const response = await axios.post(
        "https://ekoland-dziennik.pl/add_user_to_group.php",
        requestData
      );
  
      if (response.status === 200) {
        if (response.data.error === "Group not found") {
          toast.error("Grupa nie została znaleziona. Sprawdź, czy identyfikator grupy jest poprawny.");
        } else if (response.data.message === "User successfully added to the group") {
          toast.success("Dodano uczestnika poprawnie!");
          // Refresh user and group data
          fetchUsers();
          fetchGroups();
        } else if (response.data.message === "User is already in the group") {
          toast.info("Uczestnik jest już w tej grupie.");
        } else {
          toast.error("Wystąpił nieznany błąd.");
        }
      }
    } catch (error) {
      toast.error("Wystąpił błąd podczas dodawania uczestnika do grupy");
    }
  };

  const handleRemoveUserFromGroup = async (userId, groupId) => {
    const requestData = {
      user_id: userId,
      group_id: groupId,
    };

    try {
      const response = await axios.delete(
        "https://ekoland-dziennik.pl/delete_user_from_group.php",
        { data: requestData } // Use data parameter for sending JSON in the body
      );

      if (response.status === 200) {
        toast.success("Uczestnik został usunięty poprawnie!");
        // Refresh user and group data
        fetchUsers();
        fetchGroups();
      }
    } catch (error) {
      toast.error("Wystąpił błąd podczas usuwania uczestnika z grupy");
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.get("https://ekoland-dziennik.pl/readGroups.php");
  
      // Check if response data is an object
      if (typeof response.data === 'object' && response.data !== null) {
        // Map the response data to an array of groups
        const parsedGroups = Object.entries(response.data).map(([id, group]) => ({
          id,
          ...group // Spread the properties of the group object directly
        }));
  
        setGroups(parsedGroups);
      } else {
        toast.error("Nieprawidłowy format odpowiedzi!");
      }
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania grup!");
    }
  };
  const handleModifyUserActive = async (userId, activeStatus) => {
    const requestData = {
      active: activeStatus,
    };
  
    try {
      const response = await axios.put(
        `https://ekoland-dziennik.pl/modify_student_active.php?userId=${userId}`,
        requestData
      );
  
      if (response.status === 200) {
        toast.success("Zapisano status uczestnika");
        // Refresh user data
        fetchUsers();
      }
    } catch (error) {
      toast.error("Wystąpił błąd podczas zmiany statusu");
    }
  };

  const options = {

  
    textLabels: {
      body: {
        noMatch: "Nie ma żadnych uczestników :(",
        toolTip: "Sortuj",
      },
      pagination: {
        next: "Następna strona",
        previous: "Poprzednia strona",
        rowsPerPage: "Wiersze na stronę:",
        displayRows: "z" // 1-10 of 30
      },
      toolbar: {
        search: "Szukaj",
        downloadCsv: "Pobierz CSV",
        print: "Drukuj",
        viewColumns: "Zobacz kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        title: "FILTRY",
        reset: "reset",          
      },
      viewColumns: {
        title: "Pokaż kolumny"
      },
      selectedRows: {
        text: "wierszy usuniętych",
        delete: "Usuń"
      }
  }
  };
  return (
    <>
      <Grid container spacing={4}>
      <ToastContainer />
        <Grid item xs={12}>
          <Widget title="Stwórz nowego uczestnika">
            <TextField
              label="Imię"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Nazwisko"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="PESEL"
              value={pesel}
              onChange={(e) => setPesel(e.target.value)}
              fullWidth
              required
            />
            <Button startIcon={<Save/>}
            style={{color:"white"}}
              variant="contained"
              color="primary"
              onClick={handleCreateUser}
            >
              DODAJ
            </Button>
            </Widget>
            </Grid>
            </Grid>
            
            <Grid container spacing={4}>
            <Grid item xs={12}>
            <Widget title="Utwórz użytkowników z pliku CSV">
              <p>Plik .csv może wyglądać następująco:</p>
            <Typography component="div" className="codeBlock">
            Damian,Chymkowski,123456789 
    </Typography>
    <Typography component="div" className="codeBlock">

            Klaudia,Baka,123456789

    </Typography>
    <Typography component="div" className="codeBlock">
            Riko,Staniszewski,123456789
    </Typography>
          
            <div style={{paddingTop:"20px",paddingBottom:"20px"}}>
            <label for="file-upload" class="custom-file-upload">
    <input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        id="file-upload"
    />
    Wybierz plik
</label></div>
          <Button
            variant="contained"
            color="primary"
            style={{color:"white"}}
            onClick={handleCreateUsersFromCSV}
          >
            Utwórz użytkowników
          </Button>
          </Widget>
       </Grid>
       

        <Grid item xs={12}>

  <MUIDataTable
    title={"Lista uczestników"}
    data={Object.values(users)}
    options={options}
    columns={[
      {
        name: "first_name",
        label: "Imię",
      },
      {
        name: "last_name",
        label: "Nazwisko",
      },
      {
        name: "pesel",
        label: "PESEL",
      },
      {
        name: "active",
        label: "Aktywny",
        options: {
          
          customBodyRenderLite: (dataIndex) => (
            <Checkbox
              checked={users[dataIndex].active}
              onChange={(e) =>
                handleModifyUserActive(users[dataIndex].id, e.target.checked)
              }
            />
          ),
        },
      },
      {
        name: "groups",
        label: "Grupy",
        options: {
          customBodyRenderLite: (dataIndex) => (
            <div style={{ height: "100px", overflow: "auto" }}>
              {Object.values(groups).map((group) => (
                <div key={group.id}>
                  <strong>{group.name}:</strong>{" "}
                  {group.users.includes(users[dataIndex].id)
                    ? "Członek"
                    : "Nie uczestniczy"}
                  <br />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      handleAddUserToGroup(users[dataIndex].id, group.id)
                    }
                    disabled={group.users.includes(users[dataIndex].id)}
                  >
                    Dodaj
                  </Button>{" "}
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      handleRemoveUserFromGroup(users[dataIndex].id, group.id)
                    }
                    disabled={!group.users.includes(users[dataIndex].id)}
                  >
                    Usuń
                  </Button>
                  <br />
                </div>
              ))}
            </div>
          ),
        },
      },
      {
        name: "edit",
        label: "Edytuj",
        options: {
          customBodyRenderLite: (dataIndex) => (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditUserOpen(users[dataIndex])}
            >
              Edytuj
            </Button>
          ),
        },
      },
      {
        name: "delete",
        label: "Usuń",
        options: {
          customBodyRenderLite: (dataIndex) => (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                const isConfirmed = window.confirm("Czy na pewno chcesz usunąć tego użytkownika?");
                if (isConfirmed) {
                  handleDeleteUser(users[dataIndex].id);
                }
              }}
            >
              Usuń
            </Button>
          ),
        },
      },
    ]}
  />
      <Modal
        open={isModalOpen}
        onClose={handleEditUserClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{
          backgroundColor: '#fff',
          border: '2px solid #000',
          boxShadow: '0 3px 5px rgba(0, 0, 0, 0.3)',
          padding: '20px',
          width: '400px',
        }}>
          <h2>Edytuj Użytkownika</h2>
          <TextField
            label="Imię"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            required
          />
          <TextField
            label="Nazwisko"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
            required
          />
          <TextField
            label="PESEL"
            value={pesel}
            onChange={(e) => setPesel(e.target.value)}
            fullWidth
            required
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditUserSubmit}
            style={{ marginTop: '16px', marginRight: '8px' }}
          >
            Zapisz
          </Button>
          <Button
            variant="outlined"
            onClick={handleEditUserClose}
            style={{ marginTop: '16px' }}
          >
            Anuluj
          </Button>
        </div>
      </Modal>
        </Grid>
      </Grid>
    </>
  );
}