import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  alpha,
  IconButton
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
//import logo from "./logo.svg";
import logo from "./LogoEkoland.png"
import google from "../../images/google.svg";
import jwt_decode from "jwt-decode";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
import { GoogleLogin } from 'react-google-login';

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();
  const [cookiecheck, setcookiecheck] = useState(false)
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  var [ekoPass, setEkoPass] = useState("");
  const login = useRef();
  const haslo = useRef();
  const errorcode = useRef();
  const [isSignedIn, setisSignedIn] = useState(false);
  const [error2, setError2] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const [visible, setVisible] = useState(false);


  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageBase64(reader.result.split(',')[1]);
      };
      reader.readAsDataURL(file);
    }
  };

  const registerUser = (name, email, password, confirmPassword, image) => {
    if(password !== confirmPassword)
    {
      setIsLoading(false);
      setError2("Hasła nie zgadzają się!");
      return
    }
    setError2("");
    fetch('./reg.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        email: email,
        password: password,
        image: image,
        ekoPass: ekoPass
      }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setError2("Pomyślnie zarejestrowano użytkownika!");
        setIsLoading(false);
        console.log(data);
      } else {
        // Handle error here
        setError2(data.message);
        setIsLoading(false);
      }
    })
    .catch(error => {
      // Handle other types of errors (e.g., network issues)
      console.error('Error:', error);
      setIsLoading(false);
    });
  };
  const logIn = (login, password) => {

    fetch('https://ekoland-dziennik.pl/login.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            login: login,
            password: password,
        }),
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            console.log("Success!");
            setisSignedIn(true);
            errorcode.current.innerText = "";
            document.cookie = "Logincookie; expires=Thu, 18 Dec 2025 12:00:00 UTC";
            localStorage.setItem('email', data.userData.email);
            localStorage.setItem('imageUrl', data.userData.imageUrl);
            localStorage.setItem('name', data.userData.name);
            // Additional login logic if needed
            console.log(loginValue, passwordValue)
            loginUser(
              userDispatch,
              login,
              password,
              props.history,
              setIsLoading,
              setError,
            )
        } else {
            errorcode.current.innerText = "Logowanie się nie powiodło: " + data.message;
        }
    })
    .catch(error => {
        console.error('Error:', error);
    });
};
  const responseGoogle = (response) => {
    
    
    if(response.profileObj.email == "damianchymkowski@gmail.com" || 
    response.profileObj.email == "klauvdia@gmail.com" || 
    response.profileObj.email == "biuro@dogomama.com" || 
    response.profileObj.email == "nataliapajak33@gmail.com" || 
    response.profileObj.email == "dam.staniszewska@gmail.com" ||
    response.profileObj.email == "katarzyna.zaskorska@gmail.com" ||
    response.profileObj.email == "annagos1303@gmail.com" ||
    response.profileObj.email == "pawliszeliza@gmail.com" ||
    response.profileObj.email == "karhol1@gmail.com" ||
    response.profileObj.email == "martaryba96@gmail.com" ||
    response.profileObj.email == "paulina.anna.szlachetka@gmail.com" ||
    response.profileObj.email == "mmichalczuk12@gmail.com" ||
    response.profileObj.email == "dominika200035@gmail.com" ||
    response.profileObj.email == "kamil.smetny@gmail.com" ||
    response.profileObj.email == "gabipabi15@gmail.com" ||
    response.profileObj.email == "natalia.belaziorava@gmail.com" ||
    response.profileObj.email == "magdakania416@gmail.com" ||
    response.profileObj.email == "wirkijowska.natalia@gmail.com")
    {
      console.log("Success!");
      errorcode.current.innerText = ""
      setisSignedIn(true);
      document.cookie = "Logincookie; expires=Thu, 18 Dec 2025 12:00:00 UTC";
      localStorage.setItem('email', response.profileObj.email);
      localStorage.setItem('imageUrl', response.profileObj.imageUrl);
      localStorage.setItem('name', response.profileObj.givenName);
      loginUser(
        userDispatch,
        loginValue,
        passwordValue,
        props.history,
        setIsLoading,
        setError,
      )

    }
    else
    {
      console.log("Failed");
      setisSignedIn(false);
      errorcode.current.innerText = "Coś poszło nie tak!"
      
      
      
      
    }

  }
  /*
  useEffect(() => {
    var cookie = document.cookie.split(";");
    var cookies = document.cookie;
    for(let element in cookie)
    {
      if(cookie[element].endsWith("Logincookie"))
      {
    setisSignedIn(true);
    loginUser(
      userDispatch,
      loginValue,
      passwordValue,
      props.history,
      setIsLoading,
      setError,
    )
    setcookiecheck(true);
    console.log(cookies)
    
      }
    }

     

  },[])
  */
  return (
   
    <Grid container className={classes.container}>
      {/*}
       <Button onClick={()=>{
console.log("Success!");
setisSignedIn(true);
errorcode.current.innerText = "";
document.cookie = "Logincookie; expires=Thu, 18 Dec 2025 12:00:00 UTC";
localStorage.setItem('email', 'damianchymkowski@gmail.com');
localStorage.setItem('imageUrl', 'https://lol.com');
localStorage.setItem('name', 'Damian Chymkowski');
// Additional login logic if needed
console.log(loginValue, passwordValue)
loginUser(
  userDispatch,
  'a',
  'b',
  props.history,
  setIsLoading,
  setError,
)


       }}>Login</Button>
      {*/}
      <div className={classes.logotypeContainer}>
        <img src="https://static.wixstatic.com/media/2a5f39_b8306efe5ee443ffbf1526f7862163c5~mv2.jpg/v1/crop/x_0,y_239,w_1920,h_722/fill/w_351,h_132,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/10latna-strone.jpg" alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}></Typography>
      </div>
      <div className={classes.formContainer} style={{backgroundColor:"#f9f9f9"}}>
        <div className={classes.form}>
         
          {activeTabId === 0 && (
            <React.Fragment>
              

              <div className={classes.greeting}>
              <img src="https://static.wixstatic.com/media/2a5f39_b8306efe5ee443ffbf1526f7862163c5~mv2.jpg/v1/crop/x_0,y_239,w_1920,h_722/fill/w_351,h_132,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/10latna-strone.jpg" alt="logo-mini" style={{width:"50%"}} className={classes.greeting}></img>
              <Typography variant="h1" className={classes.greeting}>
                Eko Dziennik
              </Typography>
              <br></br><br></br>
              {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <>
              {/*<GoogleLogin
    clientId="1039534305222-s6q749i6l4lqs4l2i3m11lqcnprcvpcf.apps.googleusercontent.com"
    buttonText="Zaloguj się z pomocą Google"
    onSuccess={responseGoogle}
    onFailure={()=>{console.log("You don't have permissions to open this app")}}
    cookiePolicy={'single_host_origin'}
                /> 
  <Typography style={{padding:"10px"}}>lub</Typography>*/}
  <Typography style={{padding:"10px"}}>Zaloguj się za pomocą adresu email oraz hasła</Typography>
  <form>
  <TextField inputRef={login} type="text" placeholder="Adres email"></TextField>
  <TextField inputRef={haslo} type="password" placeholder="Hasło"></TextField><br></br>
  <Button type="submit" style={{color:"white",marginTop:"10px"}} color="primary" variant="contained" onClick={()=> {logIn(login.current.value, haslo.current.value)}}>Zaloguj się</Button>
  <div className={classes.formDividerContainer} style={{justifyContent:"center"}}>lub</div>
  <div className={classes.formDivider} />
  <Button variant="contained" onClick={()=>{setActiveTabId(1)}}>Zarejestruj się</Button>
  </form>

                 
  
  <h3 ref={errorcode} style={{color:"red"}}></h3>
  </>
                )}
                
              </div>
              
              <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}>

                </Typography>
                <div className={classes.formDivider} />
              </div>
             
              
              <div className={classes.formButtons}>
                
                
          
              </div>
            </React.Fragment>
          )}
          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h2" className={classes.subGreeting}>
                Zarejestruj się
              </Typography>

                <Typography style={{color:"red"}} className={classes.errorMessage}>
                  {error2}
                </Typography>

              <TextField
                id="name"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={nameValue}
                onChange={e => setNameValue(e.target.value)}
                margin="normal"
                placeholder="Imię i Nazwisko"
                type="text"
                fullWidth
              />
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Hasło"
                type={visible ? ("text"):("password")}
                style={{width:"80%"}}
              /><IconButton onClick={() => {setVisible(!visible)}}>
              {visible? (<Visibility />): (<VisibilityOff />)} 
            </IconButton><br></br>
                            <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={confirmPasswordValue}
                onChange={e => setConfirmPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Powtórz Hasło"
                type={visible ? ("text"):("password")}
                style={{width:"80%"}}
              /><IconButton onClick={() => {setVisible(!visible)}}>
              {visible? (<Visibility />): (<VisibilityOff />)} 
            </IconButton><br></br>
      <label htmlFor="imageInput">Wybierz zdjęcie profilowe:</label>
      <input
        type="file"
        id="imageInput"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: 'block', marginBottom: '10px' }}
      />
      <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={ekoPass}
                onChange={e => setEkoPass(e.target.value)}
                margin="normal"
                placeholder="Hasło Eko"
                type={visible ? ("text"):("password")}
                style={{width:"80%"}}
              />
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={() =>
                      {
                        setIsLoading(true);
                        registerUser(nameValue, loginValue, passwordValue, confirmPasswordValue, imageBase64, ekoPass)
                      }
                     
                    }
                    disabled={
                      loginValue.length === 0 ||
                      passwordValue.length === 0 ||
                      nameValue.length === 0 ||
                      confirmPasswordValue.length === 0
                    }
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    Stwórz swoje konto
                  </Button>
                )}
              </div>
              <div className={classes.formDividerContainer}>
              
                <div className={classes.formDivider} />
                lub
                <div className={classes.formDivider} />
              
               
              </div>
              <Button
                    onClick={()=>{setActiveTabId(0)}}
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                    style={{color:"white"}}
                  >
                    Zaloguj się
                  </Button>
              
            </React.Fragment>
            
          )}
                        
        </div>
        <Typography color="primary" className={classes.copyright}>
        © 2023-{new Date().getFullYear()+1} <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://ekoland.edu.pl" rel="noopener noreferrer" target="_blank">Ekoland</a>, Wszystkie prawa zastrzeżone.
        </Typography>

      </div>
    </Grid>
  );
}

export default withRouter(Login);
