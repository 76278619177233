import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
  Link
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
// styles
import useStyles from "./styles";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// components
import { Badge, Typography, Button } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import Snowfall from "react-snowfall";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";

const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

const animationDuration = 10; // Adjust the animation duration (in seconds) as needed

function NewsNotification() {
  const [filteredNews, setFilteredNews] = useState([]);
  const [animationOffset, setAnimationOffset] = useState(0);

  useEffect(() => {
    // Fetch news data from the provided URL
    fetch('https://mobileappscrapper.herokuapp.com/aktualnosci')
      .then((response) => response.json())
      .then((data) => {
        const newsArray = Object.values(data);

        const formattedNews = newsArray.map((newsItem) => ({
          content: `${newsItem.News} (${newsItem.Date})`,
          date: newsItem.Date,
        }));

        formattedNews.sort((a, b) => {
          const dateA = new Date(a.date.split('/').reverse().join('/'));
          const dateB = new Date(b.date.split('/').reverse().join('/'));
          return dateB - dateA;
        });

        setFilteredNews(formattedNews);
      })
      .catch((error) => {
        console.error('Error fetching news:', error);
      });

    // Create an animation loop
    const animateTicker = () => {
      setAnimationOffset((prevOffset) => (prevOffset + 1) % (filteredNews.length * 100));
    };

    const animationInterval = setInterval(animateTicker, 50);

    return () => clearInterval(animationInterval);
  }, [filteredNews.length]);

  return (
    <div className="newso" style={{ overflow: 'hidden', whiteSpace: 'nowrap', width: '100%', color: 'white' }}>
      {filteredNews.length > 0 ? (
        <div
          style={{
            animation: 'ticker 30s linear infinite',
            transform: `translateX(-${animationOffset}%)`,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {filteredNews.map((item) => item.content).join('     •     ')}
        </div>
      ) : (
        <p style={{ color: 'white' }}>Ładowanie...</p>
      )}
      <style>
        {`
          @keyframes ticker {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
          @media only screen and (max-width: 600px) {
            .newso {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
}


export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);


  function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=0'
}
  return (
    <AppBar position="fixed" className={classes.appBar}>
      {/*}<Snowfall snowflakeCount={50} speed={[0.5,1.0]}/>{*/}
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        
        <div className={classes.grow} />
        
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
           
          </div>
          
        </div>
        
<NewsNotification></NewsNotification>

        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          {(() => {
        if (localStorage.getItem("imageUrl")) {
          return (
            
            <img src={localStorage.getItem("imageUrl")} style={{width:"45px", height:"45px", borderRadius:"50px"}}></img>
            )
          } 
        })()}
                  {(() => {
        if (!localStorage.getItem("imageUrl")) {
          document.cookie = "Logincookie; expires=" + new Date(0).toUTCString();
          localStorage.removeItem('email');
          localStorage.removeItem('imageUrl');
          localStorage.removeItem('name');
          signOut(userDispatch, props.history);
          return (
            <AccountIcon classes={{ root: classes.headerIcon }} />
            )
          } 
        })()}

         
        </IconButton>
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
           
          </div>
          {messages.map(message => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu>
        
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          
          <div className={classes.profileMenuUser}>
          {(() => {
        if (localStorage.getItem("name")) {
          return (
            <Typography
            className={classes.profileMenuLink}
            color="black"
          >
            Witaj, {localStorage.getItem("name")}
          </Typography>
            )
          } 
        })()}
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => {signOut(userDispatch, props.history);document.cookie = "Logincookie; expires=" + new Date(0).toUTCString();}}
            ><br></br>
              Wyloguj się
            </Typography>
          </div>
        </Menu>
        
      </Toolbar>
      
    </AppBar>
    
  );
}
