import { React, useEffect, useState } from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import './styles.css';

export default function Wychowankowie() {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [grades, setGrades] = useState([]);
  const [editedData, setEditedData] = useState([]);

  // Fetch students
  const fetchStudents = async () => {
    try {
      const response = await axios.get("https://ekoland-dziennik.pl/readStudents.php");
      const studentsArray = Object.values(response.data);
      setUsers(studentsArray);
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania uczniów!");
    }
  };

  // Fetch groups
  const fetchGroups = async () => {
    try {
      const response = await axios.get("https://ekoland-dziennik.pl/readGroups.php");
      const groupsArray = Object.entries(response.data).map(([key, group]) => ({
        id: key,
        ...group
      }));
      setGroups(groupsArray);
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania grup!");
    }
  };

  // Fetch grades
  const fetchOcenyData = async () => {
    try {
      const response = await axios.get("https://ekoland-dziennik.pl/read_oceny.php");
      setGrades(response.data);
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania ocen!");
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      await fetchStudents();
      await fetchGroups();
      await fetchOcenyData();
    };
    initializeData();
  }, []);

  const combineData = () => {
    const combinedUserData = users.map(user => {
      const userGrades = grades.filter(grade => grade.user_id === user.id);
      return {
        userFirstName: user.first_name,
        userLastName: user.last_name,
        pesel: user.pesel,
        grades: userGrades
      };
    });

    const tableData = combinedUserData.flatMap(user => {
      return user.grades.map(grade => ({
        userFirstName: user.userFirstName,
        userLastName: user.userLastName,
        pesel: user.pesel,
        groupId: grade.group_Id,
        Ocena1: grade.ocena1 || '',
        Ocena2: grade.ocena2 || '',
        Ogolne: grade.ogolna || '',
        Notatki: grade.notatki || '',
      }));
    });

    // Add users without grades
    const usersWithoutGrades = users.filter(user => 
      !grades.some(grade => grade.user_id === user.id)
    ).map(user => ({
      userFirstName: user.first_name,
      userLastName: user.last_name,
      pesel: user.pesel,
      groupId: null,
      Ocena1: '',
      Ocena2: '',
      Ogolne: '',
      Notatki: '',
    }));

    setEditedData([...tableData, ...usersWithoutGrades]);
  };

  const handleCellValueChange = (newValue, rowIndex, columnName) => {
    setEditedData(prevData => {
      const updatedData = [...prevData];
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        [columnName]: newValue,
      };
      return updatedData;
    });
  };

  const handleWriteDataClick = async () => {
    if (editedData.length > 0) {
      const dataToWrite = editedData.map(user => ({
        user_id: user.userId,
        group_id: user.groupId,
        Ocena1: user.Ocena1 || null,
        Ocena2: user.Ocena2 || null,
        Ogolne: user.Ogolne || null,
        Notatki: user.Notatki || null,
      }));

      try {
        await axios.post("https://ekoland-dziennik.pl/write_oceny.php", dataToWrite, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        toast.success("Oceny zapisane pomyślnie!");
        fetchOcenyData(); // Refresh grades after saving
        combineData(); // Refresh combined data
      } catch (error) {
        toast.error("Wystąpił błąd podczas zapisywania ocen!");
      }
    } else {
      toast.info("Brak danych do zapisania.");
    }
  };

  useEffect(() => {
    combineData();
  }, [users, grades]); // Combine data whenever users or grades change

  const options = {
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: "Nie ma żadnych uczestników :(",
        toolTip: "Sortuj",
      },
      pagination: {
        next: "Następna strona",
        previous: "Poprzednia strona",
        rowsPerPage: "Wiersze na stronę:",
        displayRows: "z"
      },
      toolbar: {
        search: "Szukaj",
        downloadCsv: "Pobierz CSV",
        print: "Drukuj",
        viewColumns: "Zobacz kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        title: "FILTRY",
        reset: "reset",
      },
      viewColumns: {
        title: "Pokaż kolumny"
      },
      selectedRows: {
        text: "wierszy usuniętych",
        delete: "Usuń"
      }
    },
    customToolbar: () => (
      <div style={{ textAlign: 'left', paddingLeft: '24px', paddingBottom: '8px' }}>
        <div style={{ fontSize: '14px', color: 'red' }}>Nie zapomnij zapisać swoich zmian poprzez przycisk na dole!</div>
      </div>
    ),
  };

  return (
    <>
      <Grid container spacing={4}>
        <ToastContainer />
        <Grid item xs={12}>
          <MUIDataTable
            title={"Wychowankowie - Oceny i notatki"}
            data={editedData}
            options={options}
            columns={[
              { name: "userFirstName", label: "Imię" },
              { name: "userLastName", label: "Nazwisko" },
              { name: "pesel", label: "PESEL" },
              {
                name: "Ocena1",
                label: "Ocena I półrocze",
                options: {
                  customBodyRender: (value, tableMeta) => {
                    const { rowIndex } = tableMeta;
                    return (
                      <TextField
                        value={value || ''} // Default to empty string
                        onChange={(event) =>
                          handleCellValueChange(event.target.value, rowIndex, "Ocena1")
                        }
                        multiline
                      />
                    );
                  },
                },
              },
              {
                name: "Ocena2",
                label: "Ocena II półrocze",
                options: {
                  customBodyRender: (value, tableMeta) => {
                    const { rowIndex } = tableMeta;
                    return (
                      <TextField
                        value={value || ''} // Default to empty string
                        onChange={(event) =>
                          handleCellValueChange(event.target.value, rowIndex, "Ocena2")
                        }
                        multiline
                      />
                    );
                  },
                },
              },
              {
                name: "Ogolne",
                label: "Ocena ogólna",
                options: {
                  customBodyRender: (value, tableMeta) => {
                    const { rowIndex } = tableMeta;
                    return (
                      <TextField
                        value={value || ''} // Default to empty string
                        onChange={(event) =>
                          handleCellValueChange(event.target.value, rowIndex, "Ogolne")
                        }
                        multiline
                      />
                    );
                  },
                },
              },
              {
                name: "Notatki",
                label: "Notatki",
                options: {
                  customBodyRender: (value, tableMeta) => {
                    const { rowIndex } = tableMeta;
                    return (
                      <TextField
                        value={value || ''} // Default to empty string
                        onChange={(event) =>
                          handleCellValueChange(event.target.value, rowIndex, "Notatki")
                        }
                        multiline
                      />
                    );
                  },
                },
              },
            ]}
          />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ color: 'white', alignSelf: 'center' }}
          onClick={handleWriteDataClick}
        >
          Zapisz oceny!
        </Button>
      </div>
    </>
  );
}
